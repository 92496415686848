import styled, { css } from 'styled-components'
import { rem } from 'polished'

import { IMessageProps } from './interfaces'

export const Container = styled.p<IMessageProps>`
  font-family: 'Segoe UI Regular';
  font-size: ${rem(10)};
  color: ${({ theme }) => theme.colors.gray1};
  margin-top: 7px;
  margin-left: 17px;

  ${(props) =>
    props.kind &&
    {
      danger: css`
        color: ${({ theme }) => theme.colors.red};
      `,
      normal: css`
        color: ${({ theme }) => theme.colors.gray1};
      `
    }[props.kind]}
`
