import React, { lazy } from 'react'

import LayoutLogged from 'layouts/Logged'
import IPropLayoutLogged from 'layouts/Logged/types'

import LayoutLoggedOut from 'layouts/LoggedOut'
import IPropLayoutLoggedOut from 'layouts/LoggedOut/types'

const Details = lazy(() => import('pages/CSR/Details'))
const CSR = lazy(() => import('pages/CSR'))
const Login = lazy(() => import('pages/Login'))
const Dashboards = lazy(() => import('pages/Dashboards'))
const Insights = lazy(() => import('pages/Insights'))
const Upload = lazy(() => import('pages/Upload'))
const Reports = lazy(() => import('pages/Reports'))
const Subscription = lazy(() => import('pages/Subscription'))
const Operators = lazy(() => import('pages/Dashboards/Details'))

interface IProp {
  path: string
  title: string
  name?: string
  component: React.FC
  layout: React.FC<IPropLayoutLogged> | React.FC<IPropLayoutLoggedOut>
  isPublic?: boolean
  params?: string[]
  breadcrumbs?: boolean
}

export const routes: IProp[] = [
  {
    path: '/',
    title: 'Hvoice - Login',
    component: Login,
    isPublic: true,
    layout: LayoutLoggedOut
  },
  {
    path: '/dashboards',
    title: 'Hvoice - Dashboards',
    component: Dashboards,
    isPublic: false,
    layout: LayoutLogged
  },
  {
    path: '/upload',
    title: 'Hvoice - Base não supervisionada',
    component: Upload,
    isPublic: false,
    layout: LayoutLogged
  },
  // {
  //   path: '/upload',
  //   title: 'Hvoice - Base não supervisionada',
  //   component: UploadMetadados,
  //   isPublic: false,
  //   layout: LayoutLogged
  // },
  {
    path: '/relatorio',
    title: 'Hvoice - Predição base não supervisionada',
    component: Reports,
    isPublic: false,
    layout: LayoutLogged
  },
  {
    path: '/assinaturas',
    title: 'Hvoice - Assinaturas',
    component: Subscription,
    isPublic: false,
    layout: LayoutLogged
  },
  {
    path: '/insights',
    title: 'Insights',
    component: Insights,
    isPublic: false,
    layout: LayoutLogged
  },
  {
    path: '/sac',
    title: 'Sac',
    component: CSR,
    isPublic: false,
    layout: LayoutLogged
  },
  {
    path: '/sac/:service',
    title: 'Sac Details',
    component: Details,
    isPublic: false,
    layout: LayoutLogged
  },
  {
    path: '/dashboards/:service',
    title: 'Dashboards Details',
    component: Operators,
    isPublic: false,
    layout: LayoutLogged
  }
]
