import { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Loading from 'components/Loading'

import { routes } from './routes'

const Router: React.FC = () => {
  return (
    <Switch>
      {routes.map((item) => {
        const Component = item.component
        const Layout = item.layout
        return (
          <Route key={item.path} path={item.path} exact>
            <Helmet>
              <title>{item.title}</title>
            </Helmet>
            <Layout isPublic={item.isPublic} {...item}>
              <Suspense fallback={<Loading />}>
                <Component />
              </Suspense>
            </Layout>
          </Route>
        )
      })}
    </Switch>
  )
}

export default Router
