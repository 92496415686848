export const checkIfThisUserHasPermissionMenu = (
  userPermissions?: string[],
  permissionsPages?: string[]
) => {
  let canPass = false

  permissionsPages?.map((permissionsPage) => {
    const isPermissionToSeePage = userPermissions?.find(
      (userPermission) => userPermission === permissionsPage
    )

    if (isPermissionToSeePage) {
      canPass = true
    }
  })

  return canPass
}
