import { useStore } from 'effector-react'

import { store } from './store'
import * as actions from './actions'

export function useAbout() {
  const { isVisible, version, lastDate } = useStore(store)

  return {
    isVisible,
    version,
    lastDate,
    ...actions
  }
}
