import { useCallback, useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { useHistory } from 'react-router-dom'
import { LayoutGroup } from 'framer-motion'
import logoHvoice from 'assets/logo/Logo_HVOICE_branco.png'
import logoMiniHvoice from 'assets/logo/Logo_HVOICE_branco_mini.png'
import { useAuth } from 'modules/auth/hooks'
import { useMenu } from 'modules/menu/hooks'

import IconArrow from 'assets/icons/chevron-back-outline.svg'

import * as S from './styles'
import SwitchComponentDarkMode from 'components/Forms/Dropdown/SwitchComponentDarkMode'
import { useDarkMode } from 'modules/darkMode/hooks'
import { checkIfThisUserHasPermissionMenu } from 'helpers/checkIfThisUserHasPermissionMenu'
import { files } from './files'

const Menu: React.FC = () => {
  const { handleSetActive, handleSetIsVisible, isActive, isVisible } = useMenu()
  const { data } = useAuth()

  const history = useHistory()

  const [links, setLinks] = useState<any[]>(files)

  const { isDark, handleSetDarkMode } = useDarkMode()

  const colors = data?.isLogged && JSON.parse(data?.usersColors)

  const handleClickOutside = useCallback(() => {
    handleSetActive(null)
  }, [handleSetActive])

  const handleChangeIsActive = (labelLink?: string, isOpen?: boolean) => {
    setLinks(
      links.map((item) => {
        if (item.label === labelLink) {
          return {
            ...item,
            isActive: isOpen
          }
        }
        return item
      })
    )
  }

  const ref = useRef<any>()

  useEffect(() => {
    setLinks((prevState) =>
      prevState.map((item) => {
        if (
          item.label.includes('Light Mode') ||
          item.label.includes('Dark Mode')
        )
          if (isDark) {
            item.label = 'Light Mode'
          } else {
            item.label = 'Dark Mode'
          }
        return item
      })
    )

    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickOutside()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleClickOutside, isDark, ref])

  return (
    <>
      <S.Container
        initial={isVisible ? 'open' : 'closed'}
        animate={isVisible ? 'open' : 'closed'}
        ref={ref}
        text_color={colors?.menu?.text_color}
        backgroundColor={colors?.menu?.background_color}
        secondary_color={colors?.menu?.secondary_color}
      >
        <S.Button
          onClick={() => handleSetIsVisible(!isVisible)}
          isVisible={!isVisible}
          text_color={colors?.menu?.text_color}
          backgroundColor={colors?.menu?.background_color}
          secondary_color={colors?.menu?.secondary_color}
        >
          <ReactSVG src={IconArrow} />
        </S.Button>
        <S.LogoWrap className={isVisible ? 'normal' : 'mini'}>
          <S.Logo
            src={
              isVisible
                ? colors?.img
                  ? `data:image/png;base64,${colors?.img}`
                  : logoHvoice
                : colors?.miniLogo
                ? `data:image/png;base64,${colors?.miniLogo}`
                : colors?.img
                ? `data:image/png;base64,${colors?.img}`
                : logoMiniHvoice
            }
            className={isVisible ? 'normal' : 'mini'}
          />
        </S.LogoWrap>
        <LayoutGroup>
          <S.Nav layout>
            {links.map((item, key) => {
              if (
                checkIfThisUserHasPermissionMenu(
                  data?.findUserPermission?.permissions,
                  item.ids
                ) ||
                item.ids.some((item) => item === 'all')
              ) {
                return (
                  <S.Li
                    key={key}
                    layout
                    onClick={() => {
                      handleSetActive(isActive === key ? null : key)
                    }}
                    text_color={colors?.menu?.text_color}
                    backgroundColor={colors?.menu?.background_color}
                    secondary_color={colors?.menu?.secondary_color}
                    isActive={item.isActive}
                    addMarginTop={
                      item.label === 'Dark Mode' || item.label === 'Light Mode'
                    }
                  >
                    {!isVisible && (
                      <S.Label
                        text_color={colors?.menu?.text_color}
                        backgroundColor={colors?.menu?.background_color}
                        secondary_color={colors?.menu?.secondary_color}
                      >
                        {item.label}
                      </S.Label>
                    )}

                    {item.label.includes('Light Mode') ||
                    item.label.includes('Dark Mode') ? (
                      <SwitchComponentDarkMode
                        isChecked={isDark}
                        onChange={() => handleSetDarkMode(!isDark)}
                        isShowText
                      />
                    ) : (
                      <S.Link
                        layout
                        onClick={() => {
                          if (item.label === 'System') {
                            // setIsOpen(!open)
                            handleChangeIsActive(item.label, !item.isActive)
                            return
                          }

                          item?.action && item?.action()
                          item.route && history.push(item.route)
                        }}
                        text_color={colors?.menu?.text_color}
                        backgroundColor={colors?.menu?.background_color}
                        secondary_color={colors?.menu?.secondary_color}
                      >
                        <S.IconWrap>
                          <S.Icon
                            src={item.icon}
                            text_color={colors?.menu?.text_color}
                            backgroundColor={colors?.menu?.background_color}
                            secondary_color={colors?.menu?.secondary_color}
                          ></S.Icon>
                        </S.IconWrap>
                        <S.Span>{item.label}</S.Span>
                      </S.Link>
                    )}
                  </S.Li>
                )
              }
            })}
          </S.Nav>
        </LayoutGroup>
        <S.Bottom
          text_color={colors?.menu?.text_color}
          backgroundColor={colors?.menu?.background_color}
          secondary_color={colors?.menu?.secondary_color}
        >
          <p>{data?.name}</p>
          <p>{data?.email}</p>
          <p>
            powered by <S.LogoMini src={logoHvoice} className={'mini'} />
          </p>
        </S.Bottom>
      </S.Container>
    </>
  )
}

export default Menu
