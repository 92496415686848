import { createEffect, createEvent } from 'effector'

import signIn from './services/signIn'
import refreshToken from './services/refreshToken'

export const handleSignIn = createEffect(signIn)

export const handleLogout = createEvent()

export const handleRefreshToken = createEffect(refreshToken)
