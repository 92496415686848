import * as S from './styles'
import Switch from 'react-switch'
import darkMode from 'assets/icons/darkMode.png'
import lightMode from 'assets/icons/lightMode.png'
import { useDarkMode } from 'modules/darkMode/hooks'
import { useMenu } from 'modules/menu/hooks'
import { useAuth } from 'modules/auth/hooks'

interface ISwitchComponent {
  isChecked?: any
  onChange?: any
  isShowText?: boolean
}

const SwitchComponentDarkMode: React.FC<ISwitchComponent> = ({
  isChecked,
  onChange,
  isShowText
}) => {
  const { isDark } = useDarkMode()

  const { data: user } = useAuth()
  const colors = user?.usersColors && JSON.parse(user?.usersColors || '')

  const { isVisible } = useMenu()
  return (
    <S.Container text_color={colors?.menu?.text_color}>
      <Switch
        onChange={onChange}
        handleDiameter={20}
        height={20}
        width={!isVisible ? 35 : 40}
        checked={isChecked}
        checkedIcon={false}
        uncheckedIcon={false}
        uncheckedHandleIcon={
          <div style={{ position: 'relative' }}>
            <img
              src={darkMode}
              style={{ position: 'absolute', height: 20 }}
              alt=""
            />
          </div>
        }
        checkedHandleIcon={
          <div style={{ position: 'relative' }}>
            <img
              src={lightMode}
              style={{ position: 'absolute', height: 20 }}
              alt=""
            />
          </div>
        }
      />
      {isShowText && isVisible && (
        <span>{isDark ? 'Light mode' : 'Dark mode'}</span>
      )}
    </S.Container>
  )
}

export default SwitchComponentDarkMode
