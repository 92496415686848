import axios from 'axios'
import * as CONFIG from './apiConfig'

export const axiosInstance = axios.create({
  baseURL: CONFIG.baseURL,
  headers: {
    'content-type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(CONFIG.requestInterceptor)
axiosInstance.interceptors.response.use(
  CONFIG.responseInterceptor,
  CONFIG.responseErrorInterceptor
)

export const get = (path, params) => axiosInstance(path, params)

export const post = (path, data) => axiosInstance(path, { method: 'POST', data })

export const put = (path, data) => axiosInstance(path, { method: 'PUT', data })

export const patch = (path, data) => axiosInstance(path, { method: 'PATCH', data })

export const remove = path => axiosInstance(path, { method: 'DELETE' })

export const namespaces = {
  ...CONFIG
}
