/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'

import * as S from './styles'
import Modal from 'components/Modal'
import Button from 'components/Actions/Button'
import { useAuth } from 'modules/auth/hooks'
import InputGroup from 'components/Forms/InputGroup'

import { useHistory } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'

import { useModalTimer } from 'modules/timer/hooks'

const Timer: React.FC = () => {
  const { showModal, handleCloseModal } = useModalTimer()

  const { data, isLoading, handleSignIn, handleLogout } = useAuth()
  const [password, setPassword] = useState('')

  const history = useHistory()

  const { getRemainingTime } = useIdleTimer({
    timeout: 3000_000,
    throttle: 1000
  })

  const handleSubmitPass = async (e?: any) => {
    e.preventDefault()
    await handleSignIn({ email: data?.email || '', password: password })
    handleCloseModal()

    setPassword('')
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.ceil(getRemainingTime() / 1000) < 600) {
        localStorage.setItem('isActive', 'false')
      } else {
        localStorage.setItem('isActive', 'true')
      }
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime])

  return (
    <>
      <Modal
        handleClose={() => {
          return
        }}
        open={showModal}
        noPadding
        maxOverlay
        width="340px"
      >
        <S.ContainerModal>
          <form onSubmit={handleSubmitPass}>
            <S.Content>
              <S.Text>Insira a senha novamente</S.Text>

              <InputGroup
                placeholder="senha"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </S.Content>
            <S.Footer>
              <Button
                noPadding
                type="button"
                onClick={() => {
                  handleLogout()
                  history.push('/')
                  handleCloseModal()
                }}
              >
                Sair
              </Button>
              <Button noPadding type="submit" isLoading={isLoading}>
                Confirmar
              </Button>
            </S.Footer>
          </form>
        </S.ContainerModal>
      </Modal>
    </>
  )
}
export default Timer
