import { useStore } from 'effector-react'

import { store } from './store'
import * as actions from './actions'

export function useMenu() {
  const { isActive, isVisible } = useStore(store)

  return {
    isActive,
    isVisible,
    ...actions
  }
}
