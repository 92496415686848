import * as S from './styles'

type Props = {
  children?: React.ReactNode
}
const LoggedOut: React.FC<Props> = ({ children }) => {
  return <S.Container>{children}</S.Container>
}

export default LoggedOut
