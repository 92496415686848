import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { ILoaderProps } from './interfaces'

const settings = {
  size: css`
    width: 16px;
    height: 16px;

    border-radius: 50%;
  `,

  color: {
    default: {
      base: rgba('#FFF', 0.4),
      highlight: '#FFF'
    },
    switch: {
      base: rgba('#fff', 0.2),
      highlight: '#fff'
    },
    primary: {
      base: rgba('#fff', 0.2),
      highlight: '#fff'
    }
  }
}

export const Container = styled.div<ILoaderProps>`
  ${settings.size}

  position: relative;
  transform: translateZ(0);

  border: 2px solid ${settings.color.default.base};
  border-left-color: ${settings.color.default.highlight};

  animation: rotate 1.1s infinite linear;

  &:after {
    ${settings.size}
  }

  ${(props) =>
    props.size &&
    css`
      width: ${props.size + 4}px;
      height: ${props.size + 4}px;
    `}

  ${(props) =>
    props.switch &&
    css`
      border: 1px solid ${settings.color.switch.base};
      border-left-color: ${settings.color.switch.highlight};
    `}

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
