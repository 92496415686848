import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { motion } from 'framer-motion'
import { ReactSVG } from 'react-svg'

export type ISecondaryColors = {
  backgroundColor?: string
  secondary_color?: string
  text_color?: string
}

export interface IButton extends ISecondaryColors {
  isVisible: any
}

export const Label = styled.p<ISecondaryColors>`
  position: absolute;
  top: 50%;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#4c00ff'};
  transform: translateY(-50%);
  left: 50px;
  padding: 10px 20px 13px 15px;
  font-size: ${rem(13)};

  border-radius: 5px;
  z-index: 100;
  color: ${({ text_color }) => (text_color ? text_color : '#fff')};

  transition: 0.3s;
  white-space: nowrap;

  opacity: 0;
  visibility: hidden;
  z-index: -1;

  :before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-right: 10px solid
      ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : '#4c00ff'};
  }
`

export const Button = styled.button<IButton>`
  all: unset;

  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);

  width: 30px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: ${({ theme, secondary_color }) =>
    secondary_color ? secondary_color : theme.colors.green};
  cursor: pointer;

  ${({ isVisible }) =>
    isVisible &&
    css`
      svg {
        transform: rotate(-180deg);
      }
    `}

  svg {
    height: 14px;
    fill: #fff;
    color: #fff;
  }
`

export const LogoWrap = styled.div`
  &.mini {
    margin-bottom: 15px;
    border-radius: 10px 10px 0 0;
  }
`

export const Logo = styled(motion.img)`
  display: block;
  margin: 25px auto;
  object-fit: contain;

  &.normal {
    width: 165px;
    height: 95px;
  }

  &.mini {
    width: 30px;
    height: 30px;
  }
`

export const Nav = styled(motion.nav).attrs({
  variants: {
    open: { marginBottom: 35 },
    closed: {
      marginBottom: 0
    }
  }
})`
  padding: 0 20px 30px;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;

  height: calc(100% - 30px);
`

export const Link = styled(motion.a)<ISecondaryColors>`
  color: ${({ theme, text_color }) =>
    text_color ? text_color : theme.colors.blue2};
  font-size: ${rem(16)};
  font-family: 'Segoe UI Regular';
  text-decoration: none;
  display: block;

  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    > div {
      svg {
        color: #fff;
        fill: #fff;
      }
    }
  }
`

interface ILi extends ISecondaryColors {
  isActive?: boolean
  addMarginTop?: boolean
}

export const Li = styled(motion.li)<ILi>`
  position: relative;

  background-color: ${({ isActive }) => isActive && 'rgba(255, 255, 255, 0.2)'};

  border-radius: 8px;

  list-style: none;

  margin-top: ${({ addMarginTop }) => (addMarginTop ? 'auto' : '0')};

  :not(:last-of-type) {
    margin-bottom: 20px;
  }

  :first-of-type {
    margin-bottom: 20px;
  }

  :hover {
    > ${Link} {
      color: #fff;
      fill: #fff;
    }

    ${Label} {
      opacity: 1;
      visibility: visible;
      z-index: 1;
      transition: 0.3s;
    }
  }
`

export const Container = styled(motion.aside).attrs({
  variants: {
    open: { width: 240, transition: { duration: 0.3, delayChildren: 0.3 } },
    closed: { width: 60, transition: { duration: 0.3 } }
  }
})<ISecondaryColors>`
  position: sticky;
  top: 17px;

  width: 240px;
  height: calc(100vh - 34px);
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#4c00ff'};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 99;

  .submenu {
    padding-left: 20px;
    padding-top: 15px;

    &.closed {
      width: 200px;
      position: absolute;
      top: -18px;
      background: ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : '#4c00ff'};
      left: 50px;
      padding: 20px;
      border-radius: 0 10px 10px 0;
      z-index: 100;

      :before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: -10px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;

        border-right: 10px solid
          ${({ backgroundColor }) =>
            backgroundColor ? backgroundColor : '#4c00ff'};
      }
    }

    ${Li} {
      :first-of-type {
        margin-bottom: 0;
      }

      :not(:last-of-type) {
        margin-bottom: 10px;
      }

      :hover {
        ${Link} {
          color: #fff !important;
        }
      }

      ${Link} {
        font-size: ${rem(14)};
      }
    }
  }
`
export const IconWrap = styled(motion.div).attrs({
  variants: {
    open: {
      opacity: 1,
      display: 'block',
      marginRight: '10px',
      marginTop: '5px'
    },
    closed: {
      opacity: 1,
      display: 'block'
    }
  }
})``

export const Icon = styled(ReactSVG)<ISecondaryColors>`
  svg {
    fill: ${({ theme, text_color }) =>
      text_color ? text_color : theme.colors.blue2};
    height: 20px;
    width: 20px;

    :hover {
      color: #fff;
      fill: #fff;
    }
  }
`

export const Span = styled(motion.span).attrs({
  variants: {
    open: {
      opacity: 1,
      display: 'block'
    },
    closed: {
      opacity: 0,
      display: 'none'
    }
  }
})``

export const Bottom = styled(motion.div).attrs({
  variants: {
    open: {
      opacity: 1,
      display: 'block'
    },
    closed: {
      opacity: 0,
      display: 'none'
    }
  }
})<ISecondaryColors>`
  margin-top: auto;
  border-top: solid 1px
    ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#4c00ff')};
  padding: 16px 20px;

  p {
    color: ${({ theme, text_color }) =>
      text_color ? text_color : theme.colors.white};
    font-family: 'Segoe UI Regular';

    :nth-child(1) {
      font-size: ${rem(12)};
    }

    :nth-child(2) {
      font-size: ${rem(10)};
    }
    :nth-child(3) {
      font-size: ${rem(12)};
      margin-top: 10px;

      display: flex;

      align-items: center;
      gap: 3px;
    }
  }
`

export const LogoMini = styled.img`
  margin-top: 5px;
  width: 55px;
  object-fit: cover;
`

interface TWrapper extends ISecondaryColors {
  withFilter?: boolean
}
export const Wrapper = styled.div<TWrapper>`
  position: sticky;
  right: 16px;
  top: 15px;
  z-index: 99;
  background-color: #4c00ff;
  /* padding: 20px; */
  /* padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px; */
  color: white;

  ${({ withFilter, secondary_color }) =>
    withFilter &&
    css`
      :before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: ${({ theme }) =>
          secondary_color ? secondary_color : theme.colors.green};

        position: absolute;
        /* border: 20px solid red; */
        right: -5px;
        top: -5px;
        border-radius: 100%;
        z-index: 15;
      }
    `}

  ${Icon} {
    position: relative;
    z-index: 3;
  }
`
interface TContainer extends ISecondaryColors {
  open?: boolean
  isMini?: boolean
}

export const Overlay = styled.div<TContainer>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.gray1};
  opacity: ${({ open }) => (open ? 0.5 : 1)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  z-index: ${({ open }) => (open ? 1 : -1)};
`

export const ContainerModal = styled.div<TContainer>`
  opacity: ${({ open }) => (open ? 1 : 0)};
  display: ${({ open }) => (open ? 'block' : 'none')};
  z-index: ${({ open }) => (open ? 1 : -1)};
  transition: 0.3s;
  padding: 15px;
  width: 250px;
  position: fixed;
  left: ${({ isMini }) => (isMini ? '270px' : '95px')};
  top: ${({ isMini }) =>
    isMini ? 'calc(100vh - 290px)' : 'calc(100vh - 175px)'};
  background: ${({ theme }) => theme.colors.backGround_cards};

  box-shadow: 0px 3px 6px #00000029;
  border-top-left-radius: 10px;
  border-radius: 10px;
`
