// import Lottie from 'react-lottie'

import { useAuth } from 'modules/auth/hooks'
import * as S from './styles'

import sourceLogoMini from 'assets/logo/newHVoiceLogo.png'
// import animationData from './waves.json'

// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice'
//   }
// }

const Loading = () => {
  const { data: user } = useAuth()
  const colors = user?.usersColors && JSON.parse(user?.usersColors || '')

  return (
    <S.Container>
      <S.LogoAnimated
        src={
          colors?.logoLoading
            ? `data:image/png;base64,${colors?.logoLoading}`
            : sourceLogoMini
        }
      />
      <S.Description backgroundColor={colors?.menu?.background_color}>
        Aguarde, carregando componentes...
      </S.Description>

      {/* <Lottie options={defaultOptions} height="50vh" width="100vw" /> */}
    </S.Container>
  )
}

export default Loading
