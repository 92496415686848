import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 17px;

  background-color: ${({ theme }) => theme.colors.backGround_back};
`

export const Wrap = styled.div<{ noPadding?: boolean }>`
  ${({ noPadding, theme }) => css`
    position: relative;

    flex: 1;

    margin-left: 17px;
    padding: ${noPadding ? '0' : '20px 30px'};

    background: ${() => theme.colors.backGround};
    border-radius: 10px;
    width: 0;
  `}
`

export const ContainerButton = styled.div`
  display: flex;

  justify-content: flex-end;
  position: sticky;
`
