import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Toggle = styled.button.attrs({
  type: 'button'
})`
  all: unset;

  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);

  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 4px;
  transition: 0.3s;

  :hover {
    transition: 0.3s;
    opacity: 0.7;
  }
`

export const Icon = styled.img`
  height: 13.5px;
`
