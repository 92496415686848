import { axiosInstance, namespaces } from 'services/api'

import { removeCookieValue, setCookieValue } from 'helpers/cookies'
import CryptoJS from 'crypto-js'

import { IAuth } from '../types'

type TPayload = {
  email: string
  password: string
}

const signIn = async (payload: TPayload): Promise<IAuth> => {
  try {
    const { email, password } = payload
    removeCookieValue(namespaces.TOKEN_NAME)
    axiosInstance.defaults.headers.common['Authorization'] = ''

    axiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = ``

      return config
    })

    const response = await axiosInstance.post(
      namespaces.baseURLLogin,
      {
        email,
        password,
        returnSecureToken: true
      },
      {
        params: {
          key: namespaces.API_KEY
        }
      }
    )

    const { data } = response

    setCookieValue(namespaces.TOKEN_NAME, data.idToken || '')

    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${data.idToken}`

    axiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${data.idToken}`

      return config
    })

    const [
      { data: userInfo },
      { data: permission },
      { data: dynamics_graphics }
    ] = await Promise.all([
      await axiosInstance.get(namespaces.baseURLNamespaceUsers, {
        headers: {
          Authorization: `Bearer ${data.idToken}`
        }
      }),

      await axiosInstance.get(namespaces.baseURLNameSpaceFiltersPermissions, {
        headers: {
          Authorization: `Bearer ${data.idToken}`
        }
      }),

      await axiosInstance.get(
        namespaces.baseURLNameSpaceFiltersPermissionsDynamicGraphics,
        {
          headers: {
            Authorization: `Bearer ${data.idToken}`
          }
        }
      )
    ])

    const { data: usersColors } = await axiosInstance.get(
      namespaces.baseURLNameSpaceFiltersClientsColors,
      {
        headers: {
          Authorization: `Bearer ${data.idToken}`
        },
        params: {
          client: userInfo.client
        }
      }
    )

    const lastRefresh = new Date()

    let findUserPermission: any = undefined

    findUserPermission = permission[userInfo?.role || '']

    if (!findUserPermission) {
      if (permission) {
        Object.keys(permission).forEach(function (chave) {
          if (permission[chave]?.default === true) {
            findUserPermission = permission[chave]
          }
        })
      }
    }

    const secretKey: string = process.env.REACT_APP_NAMESPACE_NAME ?? '' // Use environment variable or a secure way to store your secret key

    const role = CryptoJS.AES.encrypt(
      JSON.stringify(userInfo.role || secretKey),
      secretKey
    ).toString()

    const client = CryptoJS.AES.encrypt(
      JSON.stringify(data.client),
      secretKey
    ).toString()

    const project = CryptoJS.AES.encrypt(
      JSON.stringify(userInfo.project),
      secretKey
    ).toString()

    const userEmail = CryptoJS.AES.encrypt(
      JSON.stringify(data.email),
      secretKey
    ).toString()

    const dynamics = CryptoJS.AES.encrypt(
      JSON.stringify(dynamics_graphics),
      secretKey
    ).toString()

    const userPermission = CryptoJS.AES.encrypt(
      JSON.stringify(findUserPermission),
      secretKey
    ).toString()

    setCookieValue(namespaces.TOKEN_NAME, data.idToken || '')
    setCookieValue('role', role || '')
    setCookieValue('client', client || '')
    setCookieValue(namespaces.REFRESH_TOKEN_NAME, data.refreshToken || '')
    setCookieValue('isLogged', true || '')
    setCookieValue('project', project || '')
    setCookieValue(namespaces.LAST_REFRESH, lastRefresh)
    setCookieValue('email', userEmail || '')
    localStorage.setItem('findUserPermission', userPermission || '')
    localStorage.setItem('usersColors', JSON.stringify(usersColors) || '')
    localStorage.setItem('timer', String('3600'))
    localStorage.setItem('dynamics_graphics', dynamics || '')

    const responseData: IAuth = {
      accessToken: data.idToken,
      email: userInfo.email,
      name: userInfo.name,
      role: userInfo.role || secretKey,
      client: userInfo.client || '',
      project: userInfo.project || '',
      refreshToken: data.refreshToken,
      isLogged: true,
      lastRefresh,
      usersColors: JSON.stringify(usersColors),
      dynamics_graphics: dynamics_graphics,
      findUserPermission: findUserPermission
    }

    return responseData
  } catch (err) {
    throw err
  }
}

export default signIn
