import IconDashboard from 'assets/icons/dashboard.svg'
import IconUpload from 'assets/icons/upload.svg'
import IconFileOpen from 'assets/icons/file-alt-solid.svg'
import IconFile from 'assets/icons/file-contract-solid.svg'
import IconInfo from 'assets/icons/ionic-md-information-circle.svg'
import IconLogout from 'assets/icons/open-account-logout.svg'

import { handleLogout } from 'modules/auth/actions'
import { toggleAbout } from 'modules/about/actions'
import system from 'assets/icons/system.svg'
import { toggleSystem } from 'modules/configSystem/actions'

export const files = [
  {
    label: 'Dashboards',
    icon: IconDashboard,
    route: '/dashboards',
    ids: ['dashboards/general']
  },
  // {
  //   label: 'Insights',
  //   icon: IconIdea,
  //   route: '/insights'
  // },
  // {
  //   label: 'SAC',
  //   icon: IconEarPhone,
  //   route: '/sac'
  // },
  {
    label: 'Upload',
    icon: IconUpload,
    route: '/upload',
    ids: ['upload/general', 'upload/atendimentos', 'upload/metadata']
  },
  {
    label: 'Relatórios',
    icon: IconFileOpen,
    route: '/relatorio',
    ids: ['reports/general']
  },
  {
    label: 'Assinaturas',
    icon: IconFile,
    route: '/assinaturas',
    ids: ['subscription/general']
  },
  {
    label: 'Dark Mode',
    icon: system,
    action: () => toggleSystem(),
    isActive: false,
    ids: ['all']
  },
  {
    label: 'Sobre Hvoice',
    icon: IconInfo,
    action: () => toggleAbout(),
    ids: ['all']
  },
  {
    label: 'Logout',
    icon: IconLogout,
    action: () => handleLogout(),
    ids: ['all']
  }
]
