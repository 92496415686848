import { createStore } from 'effector'

import * as actions from './actions'
import { handleLogout } from '../auth/actions'

interface IBreadCrumb {
  isDark: boolean
}

const initialState = {
  isDark:
    localStorage.getItem('isDarkMode') === undefined ||
    localStorage.getItem('isDarkMode') === null
      ? ''
      : JSON.parse(localStorage.getItem('isDarkMode') || '')
}
export const store = createStore<IBreadCrumb>(
  {
    ...initialState
  },
  {
    name: 'payload-additional'
  }
)

store.on(handleLogout, () => ({
  ...initialState
}))

store.on(actions.handleSetDarkMode, (state, payload) => {
  localStorage.setItem('isDarkMode', JSON.stringify(payload || '') || '')

  return {
    ...state,
    isDark: payload
  }
})
