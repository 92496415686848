import { useStore } from 'effector-react'

import { store } from './store'
import * as actions from './actions'

export function useDarkMode() {
  const { isDark } = useStore(store)

  return {
    isDark,
    ...actions
  }
}
