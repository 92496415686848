import { ThemeProvider } from 'styled-components'
import { GridThemeProvider } from 'styled-bootstrap-grid'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import GlobalStyles from 'styles/global'
import theme from 'styles/theme'
import { useDarkMode } from 'modules/darkMode/hooks'
import themeDark from 'styles/themeDark'
import { useEffect } from 'react'

type Props = {
  children?: React.ReactNode
}
const AppProviders: React.FC<Props> = ({ children }) => {
  const { isDark, handleSetDarkMode } = useDarkMode()

  useEffect(() => {
    const isDarkMode =
      localStorage.getItem('isDarkMode') === undefined ||
      localStorage.getItem('isDarkMode') === null
        ? ''
        : JSON.parse(localStorage.getItem('isDarkMode') || '')

    handleSetDarkMode(isDarkMode === '' ? false : true)
  }, [handleSetDarkMode, isDark])

  return (
    <ThemeProvider theme={isDark === true ? theme : themeDark}>
      <GridThemeProvider
        gridTheme={isDark === true ? theme.grid : themeDark.grid}
      >
        <>
          <GlobalStyles />
          <ToastContainer
            position="top-right"
            autoClose={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
          />
          {children}
        </>
      </GridThemeProvider>
    </ThemeProvider>
  )
}

export default AppProviders
