/* eslint-dis able prettier/prettier */
const theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    white1: '#F1F1F1',
    gray0: '#E3E3E3',
    gray1: '#35524A',
    gray2: '#779CAB',
    gray3: '#E3E3E3',
    gray4: '#B4B4B4',
    gray5: '#DCDCDC',
    gray6: '#f0f0f0',
    blue1: '#4C00FF ',
    blue2: '#A2E8DD',
    green: '#32DE8A',
    red: '#E41E1E',
    red1: '#E8A2A2',
    orange: '#ff6f00',
    yellow: '#FBDB9D',
    backGround: '#FFFFFF',
    backGround_back: '#f5f5f5',
    backGround_cards: '#F0F0F0',
    text: '#35524A'
  },
  gutter: 14,
  grid: {
    breakpoints: {
      xxl: 1400,
      xl: 1200,
      lg: 992,
      md: 768,
      sm: 576,
      xs: 0
    },
    row: {
      padding: 7
    },
    col: {
      padding: 7
    },
    container: {
      padding: 7,
      maxWidth: {
        xxl: 1320,
        xl: 1140,
        lg: 960,
        md: 720,
        sm: 540,
        xs: 0
      }
    }
  },
  spacing: (number: number) => number * 7
} as const

import 'styled-components'

type Theme = any

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export default theme
