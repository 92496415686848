import * as S from './styles'

import files from './list'
import { HtmlHTMLAttributes } from 'react'

interface TIcon extends HtmlHTMLAttributes<HTMLImageElement> {
  name?: keyof typeof files
  height?: number
  pointer?: boolean
  onClick?: () => void
  className?: string
}

const Icon = ({ name = 'add', height, pointer, onClick, ...props }: TIcon) => {
  return (
    <S.Container
      pointer={pointer}
      src={files[name]}
      height={height}
      onClick={onClick}
      {...props}
    />
  )
}

export default Icon
