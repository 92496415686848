import { createStore } from 'effector'

import * as actions from './actions'

interface IUsePayloadDashboard {
  showModal?: boolean
}

const initialState = {
  showModal: false
}
export const store = createStore<IUsePayloadDashboard>(
  {
    ...initialState
  },
  {
    name: 'payload-dashboard'
  }
)

store.on(actions.handleOpenModal, (state) => ({
  ...state,
  showModal: true
}))
store.on(actions.handleCloseModal, (state) => ({
  ...state,
  showModal: false
}))
