import axios from 'axios'
import querystring from 'querystring'

import { namespaces, axiosInstance } from 'services/api'

import { setCookieValue, getCookieValue } from 'helpers/cookies'

import { IAuth } from '../types'

const refreshToken = async (): Promise<IAuth> => {
  try {
    const refresh_token = getCookieValue(namespaces.REFRESH_TOKEN_NAME)
    const header = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    const parameters = {
      header,
      params: {
        key: namespaces.API_KEY
      }
    }
    const body = {
      grant_type: 'refresh_token',
      refresh_token
    }

    const response = await axios.post(
      namespaces.baseURLRefreshToken,
      querystring.stringify(body),
      parameters
    )

    const { data } = response
    const lastRefresh = new Date()

    const { data: userInfo } = await axiosInstance.get(
      namespaces.baseURLNamespaceUsers,
      {
        headers: {
          Authorization: `Bearer ${data.idToken}`
        }
      }
    )

    const responseData: IAuth = {
      accessToken: data.id_token,
      email: userInfo.email,
      name: userInfo.name,
      refreshToken: data.refresh_token,
      isLogged: true,
      lastRefresh
    }

    setCookieValue(namespaces.TOKEN_NAME, responseData.accessToken || '')
    setCookieValue(
      namespaces.REFRESH_TOKEN_NAME,
      responseData.refreshToken || ''
    )
    setCookieValue(namespaces.LAST_REFRESH, lastRefresh)

    return responseData
  } catch (err) {
    throw err
  }
}

export default refreshToken
