import { useAbout } from 'modules/about/hooks'

import Modal from 'components/Modal'

import * as S from './styles'
import { useAuth } from 'modules/auth/hooks'

const ModalAbout = () => {
  const { toggleAbout, isVisible, version, lastDate } = useAbout()

  const { data: user } = useAuth()
  const colors = user?.usersColors && JSON.parse(user?.usersColors || '')

  return (
    <Modal handleClose={toggleAbout} open={isVisible} noPadding>
      <S.Container>
        <S.Header>
          {colors?.logoLoading ? (
            <S.Logos>
              <S.Logo />
              <S.Divider backgroundColor={colors?.menu?.background_color} />
              <S.LogoClient
                src={`data:image/png;base64,${colors?.logoLoading}`}
              />
            </S.Logos>
          ) : (
            <>
              <S.Logo />
            </>
          )}

          <S.Text>
            <b>Versão: {version}</b> <br />
            <span>última atualização em: {lastDate}</span>
          </S.Text>
        </S.Header>
        <S.Content>
          <S.Text>
            O HVoice é um produto desenvolvido pela HVAR Consulting.
          </S.Text>
          <br />

          <S.Text>
            Com um time de especialistas em negócio e tecnologia, a Hvar
            Consulting atua com Data & Analytics desde 2011.
          </S.Text>
          <br />

          <S.Text>
            O conhecimento de negócio adquirido pela HVAR Consulting ao longo
            dos últimos dez anos, combinado com a utilização das mais recentes
            tecnologias de Speech Analytics, Text Mining e Big Data e a parceria
            com o Google fazem do HVoice um produto robusto e com infraestrutura
            escalável.
          </S.Text>
          <br />
          <S.Text>
            <b>Saiba mais em:</b> &nbsp;
            <a href="https://hvoice.com.br" target="_blank" rel="noreferrer">
              www.hvoice.com.br
            </a>
          </S.Text>
        </S.Content>

        <S.Footer>
          <S.Text>
            <a
              href="https://hvarconsulting.com.br"
              target="_blank"
              rel="noreferrer"
            >
              www.hvarconsulting.com.br
            </a>
          </S.Text>

          <a
            href="https://www.hvarconsulting.com.br"
            target="_blank"
            rel="noreferrer"
          >
            <S.LogoHvar />
          </a>
        </S.Footer>
      </S.Container>
    </Modal>
  )
}

export default ModalAbout
