import { createStore } from 'effector'

import { setCookieValue, getCookieValue } from 'helpers/cookies'

import { IMenu } from './types'

import * as actions from './actions'

const menuInfo = getCookieValue('menu')
const initialState = menuInfo
  ? JSON.parse(menuInfo)
  : {
      isVisible: true,
      isActive: null
    }

export const store = createStore<IMenu>(
  {
    ...initialState
  },
  {
    name: 'menu'
  }
)

store.on(actions.handleSetActive, (state, payload) => {
  const info = {
    ...state,
    isActive: payload
  }

  setCookieValue('menu', JSON.stringify(info))

  return {
    ...info
  }
})

store.on(actions.handleSetIsVisible, (state, payload) => {
  const info = {
    ...state,
    isVisible: payload
  }
  setCookieValue('menu', JSON.stringify(info))

  return {
    ...info
  }
})
