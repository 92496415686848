import * as S from './styles'

import Icon from 'components/Icons/Icon'
import { IButtonProps } from './interfaces'
import { useAuth } from 'modules/auth/hooks'

const Button: React.FC<IButtonProps> = (props) => {
  const { data: user } = useAuth()
  const colors = user?.usersColors && JSON.parse(user?.usersColors || '')

  return (
    <S.Container
      {...props}
      backgroundColor={
        props.optionalBackground
          ? props.optionalBackground
          : colors?.menu?.background_color
      }
      secondary_color={colors?.menu?.secondary_color}
    >
      {props.isLoading ? <S.Loader /> : props.children}
      {!props.isLoading && props.icon ? (
        <Icon name={props.icon} height={14} />
      ) : (
        <></>
      )}
    </S.Container>
  )
}

Button.defaultProps = {
  children: 'Button',
  color: 'primary',
  fill: 'solid',
  disabled: false,
  isLoading: false
}

export default Button
