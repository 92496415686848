import { ISecondaryColors } from 'layouts/Menu/styles'
import styled from 'styled-components'

export const Container = styled.div<ISecondaryColors>`
  display: flex;
  align-items: center;
  /* background-color: ${({ theme }) => theme.colors.gray1}; */
  /* justify-content: center; */
  /* flex-direction: column; */

  gap: 10px;
  span {
    font-weight: bold;
    color: ${({ text_color }) => text_color};
  }
  .react-switch-bg {
    /* border: 1px solid red; */
    background: ${({ theme }) => theme.colors.backGround} !important;
  }
`
