import styled, { css } from 'styled-components'

type TWrapper = {
  open?: boolean
  noPadding?: boolean
  width?: string
  maxOverlay?: boolean
}

export const Container = styled.div<TWrapper>`
  background-color: ${({ theme }) => theme.colors.backGround_cards};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px;

  min-width: ${({ width }) => (width ? width : '700px')};
`

export const Overlay = styled.div<TWrapper>`
  background-color: ${({ theme }) => theme.colors.gray1};
  opacity: ${({ maxOverlay }) => (maxOverlay ? 1 : 0.5)};
  position: fixed;

  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`

export const Wrapper = styled.div<TWrapper>`
  position: fixed;
  height: 100vh;
  width: 100vw;

  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  z-index: ${({ open }) => (open ? 999999 : -1)};
  transition: 0.3s;

  left: 0;
  top: 0;

  ${Container} {
    transition: 0.3s;
    margin-top: ${({ open }) => (open ? '20px' : '-20px')};

    ${({ noPadding }) =>
      noPadding &&
      css`
        padding: 20px;
      `}
  }
`
