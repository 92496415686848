import styled from 'styled-components'

import sourceLogo from 'assets/logo/newHVoiceLogo.png'
import sourceLogoHvar from 'assets/logo/logo_hvar.png'
import { ISecondaryColors } from 'layouts/Menu/styles'

export const Container = styled.div``

export const Logo = styled.img.attrs({
  src: sourceLogo
})`
  height: 64px;

  margin-top: -20px;
`

export const LogoClient = styled.img`
  height: 50px;
  margin-top: -20px;
`
export const Divider = styled.div<ISecondaryColors>`
  height: 50px;
  border-right: 1px solid
    ${({ backgroundColor, theme }) =>
      backgroundColor ? backgroundColor : theme.colors.blue1};

  margin-right: 10px;
  margin-left: 15px;
  margin-bottom: 20px;
`

export const Logos = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
`

export const LogoHvar = styled.img.attrs({
  src: sourceLogoHvar
})`
  height: 28px;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  line-height: 150%;
  font-family: 'Segoe UI Regular';

  * {
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${Text} {
    text-align: right;
  }
  span {
    color: ${({ theme }) => theme.colors.text};
    font-size: 10px;
  }
`

export const Content = styled.div`
  padding: 10px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.backGround};
  border-bottom: 1px solid ${({ theme }) => theme.colors.backGround};
`

export const Footer = styled.div`
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: #779cab;
    text-decoration: none;
  }
`
