import { useState } from 'react'

import Input from '../Input'
import Message from '../Message'

import Eye from '../../../assets/icons/eye.svg'
import EyeOff from '../../../assets/icons/eye-off.svg'

import { InputGroupProps } from './interfaces'

import * as S from './styles'

const InputGroup: React.FC<InputGroupProps> = ({
  error,
  helperText,
  ...props
}) => {
  const [hidden, setHidden] = useState(false)

  function showPassword() {
    setHidden(!hidden)
  }

  return (
    <S.Container>
      <Input
        {...props}
        error={!!error}
        type={props.type === 'password' && hidden ? 'text' : props.type}
      />
      {!!helperText && (
        <Message kind={error ? 'danger' : 'normal'}>{helperText}</Message>
      )}
      {props.type === 'password' && (
        <S.Toggle onClick={showPassword}>
          <S.Icon src={hidden ? EyeOff : Eye}></S.Icon>
        </S.Toggle>
      )}
    </S.Container>
  )
}

export default InputGroup
