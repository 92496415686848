import styled from 'styled-components'

type TContainer = {
  height?: number
  pointer?: boolean
}

export const Container = styled.img<TContainer>`
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
`
