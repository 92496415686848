import styled, { css } from 'styled-components'
import { rem } from 'polished'

import { IFieldProps } from './interfaces'

export const Container = styled.input<IFieldProps>`
  padding: 13px 17px;
  width: 100%;

  font-family: 'Segoe UI Regular';
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.backGround};
  color: ${({ theme }) => theme.colors.text};
  font-weight: normal;
  font-size: ${rem(14)};
  outline: none;
  transition: 0.3s;

  ::placeholder {
    color: ${({ theme }) => theme.colors.text};
  }

  :hover,
  :focus {
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.3s;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.red};

      ::placeholder {
        color: ${({ theme }) => theme.colors.red};
      }

      &:focus {
        border-color: ${({ theme }) => theme.colors.red};
      }
    `}
`
