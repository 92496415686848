import { motion } from 'framer-motion'
import { ISecondaryColors } from 'layouts/Menu/styles'
import { ReactSVG } from 'react-svg'

import styled, { css, keyframes } from 'styled-components'

export const IconWrap = styled(motion.div).attrs({
  variants: {
    open: {
      opacity: 1,
      display: 'block',

      marginTop: '5px'
    },
    closed: {
      opacity: 1,
      display: 'block'
    }
  }
})``

const rotateClock = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const ClockContainer = styled.div`
  display: inline-block;
`

export const ClockImage = styled.img<{ isRotating: boolean }>`
  width: 20px; /* Ajuste o tamanho conforme necessário */
  height: 20px;
  transition: transform 1s linear;

  ${({ isRotating }) =>
    isRotating &&
    css`
      animation: ${rotateClock} 2s linear infinite;
    `};
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  margin-top: 15px;
  margin-left: 2px;

  :hover {
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  p {
    font-size: 18px;
  }
`

export const Icon = styled(ReactSVG)<ISecondaryColors>`
  svg {
    fill: ${({ theme, text_color }) =>
      text_color ? text_color : theme.colors.blue2};
    height: 20px;
    width: 20px;
  }
`

export const ContainerModal = styled.div``

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  line-height: 150%;
  font-family: 'Segoe UI Regular';

  * {
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${Text} {
    text-align: right;
  }
  span {
    color: #c4c4c4;
    font-size: 10px;
  }
`

export const Content = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;

  gap: 10px;

  font-weight: bold;
`

export const Footer = styled.div`
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`

export const Tooltip = styled.div`
  cursor: pointer;
  display: block;
  position: relative;

  span {
    position: absolute;
    text-align: center;
    visibility: hidden;
    background-color: ${({ theme }) => theme.colors.backGround};
    color: ${({ theme }) => theme.colors.text};
    padding: 5px 10px;
    width: 110px;
  }

  span::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%; /* Mudei para o centro horizontal */
    margin-left: -8px;
    width: 0;
    height: 0;
    border-bottom: 8px solid ${({ theme }) => theme.colors.backGround};
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

  :hover {
    span {
      visibility: visible;
      opacity: 0.9;
      top: 50px;
      left: -10px; /* Mudei para a esquerda */
      z-index: 999;
    }
  }
`
