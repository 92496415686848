import { useEffect, useMemo } from 'react'

import * as S from './styles'

import Menu from '../Menu'
import Modal from '../ModalAbout'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'modules/auth/hooks'
import Timer from 'components/Timer'

type Props = {
  children?: React.ReactNode
}
const Logged: React.FC<Props> = ({ children }) => {
  const history = useHistory()
  const { data, handleLogout } = useAuth()

  const url = window.location.href
    ? window.location.href.includes('relatorio')
    : false

  useEffect(() => {
    if (!data?.isLogged || !data?.role) {
      handleLogout()
      history.push('/')
    }
  }, [data?.isLogged, data?.role, handleLogout, history])

  return (
    <>
      <Modal />
      <Timer />

      <S.Container>
        {useMemo(
          () => (
            <Menu />
          ),
          []
        )}
        <S.Wrap noPadding={url}>{children}</S.Wrap>
      </S.Container>
    </>
  )
}

export default Logged
