import { ISecondaryColors } from 'layouts/Menu/styles'
import styled, { keyframes } from 'styled-components'

const pulseWhite = keyframes`
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backGround};
  width: 100%;
  height: 100%;

  z-index: 99999999;
`

export const LogoAnimated = styled.img.attrs({})<{ logoLoading?: any }>`
  animation: ${pulseWhite} 2s infinite;
  object-fit: cover;
  width: 200px;
`
export const Description = styled.p<ISecondaryColors>`
  font-family: 'Segoe UI SemiBold';
  color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.blue1};
`
