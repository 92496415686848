import accountLogout from 'assets/icons/account-logout.svg'
import filePdf from 'assets/icons/file-pdf.svg'
import add from 'assets/icons/add.svg'
import download from 'assets/icons/download.svg'
import addWhite from 'assets/icons/add-white.svg'

import fileVideo from 'assets/icons/file-video.svg'
import arrowsAltV from 'assets/icons/arrows-alt-v.svg'
import fileWord from 'assets/icons/file-word.svg'
import colorPalette from 'assets/icons/color-palette.svg'
import filter from 'assets/icons/filter.svg'
import edit from 'assets/icons/edit.svg'
import fullscreen from 'assets/icons/fullscreen.svg'
import edit2 from 'assets/icons/edit-2.svg'
import fullscreenExit from 'assets/icons/fullscreen-exit.svg'
import eye from 'assets/icons/eye.svg'
import clearFilterIcon from 'assets/icons/icon-clear-filter.svg'
import check from 'assets/icons/tick-mark.png'
import information from 'assets/icons/information.png'

// import informationCircle from 'assets/icons/information-circle.svg'
import eyeOff from 'assets/icons/eye-off.svg'
import ionicIosAddCircle from 'assets/icons/ionic-ios-add-circle.svg'
import face from 'assets/icons/face.svg'
import ionicIosAddCircleOutline from 'assets/icons/ionic-ios-add-circle-outline.svg'
import featherEye from 'assets/icons/feather-eye.svg'
import ionicIosarrowback from 'assets/icons/ionic-ios-arrow-back.svg'
import featherEyeOff from 'assets/icons/feather-eye-off.svg'
import ionicIosRemoveCircle from 'assets/icons/ionic-ios-remove-circle.svg'
import featherFile from 'assets/icons/feather-file.svg'
import ionicIosRemoveCircleOutline from 'assets/icons/ionic-ios-remove-circle-outline.svg'
import featherFileMinus from 'assets/icons/feather-file-minus.svg'
import loader from 'assets/icons/loader.svg'
import search from 'assets/icons/search.svg'
import featherFilePlus from 'assets/icons/feather-file-plus.svg'
import mapFullscreen from 'assets/icons/map-fullscreen.svg'
import featherFileText from 'assets/icons/feather-file-text.svg'
import materialEdit from 'assets/icons/material-edit.svg'
import featherMusic from 'assets/icons/feather-music.svg'
import movie from 'assets/icons/movie.svg'
import featherUserCheck from 'assets/icons/feather-user-check.svg'
import music from 'assets/icons/music.svg'
import featherUsers from 'assets/icons/feather-users.svg'
import remove from 'assets/icons/remove.svg'
import fileAlt from 'assets/icons/file-alt.svg'
import tagFaces from 'assets/icons/tag-faces.svg'
import fileAudio from 'assets/icons/file-audio.svg'
import trash from 'assets/icons/trash.svg'
import fileCode from 'assets/icons/file-code.svg'
import trash2 from 'assets/icons/trash-2.svg'
import fileContract from 'assets/icons/file-contract.svg'
import trashAlt from 'assets/icons/trash-alt.svg'
import fileCsv from 'assets/icons/file-csv.svg'
import trashAlt1 from 'assets/icons/trash-alt-1.svg'
import fileDownload from 'assets/icons/file-download.svg'
import trashAlt2 from 'assets/icons/trash-alt-2.svg'
import fileExcel from 'assets/icons/file-excel.svg'
import upload from 'assets/icons/upload.svg'
import uploadWhite from 'assets/icons/upload-white.svg'
import fileExport from 'assets/icons/file-export.svg'
import user from 'assets/icons/user.svg'
import fileImage from 'assets/icons/file-image.svg'
import userAlt from 'assets/icons/user-alt.svg'
import fileImport from 'assets/icons/file-import.svg'
import userCheck from 'assets/icons/user-check.svg'
import fileInvoice from 'assets/icons/file-invoice.svg'
import userCircle from 'assets/icons/user-circle.svg'
import fileInvoiceDollar from 'assets/icons/file-invoice-dollar.svg'
import userEdit from 'assets/icons/user-edit.svg'
import fileMedicalAlt from 'assets/icons/file-medical-alt.svg'
import users from 'assets/icons/users.svg'
import fileMovie from 'assets/icons/file-movie.svg'
import sortDown from 'assets/icons/sort-down.svg'
import sortUp from 'assets/icons/sort-up.svg'
import xCircle from 'assets/icons/x-circle.svg'
import verifiedUser from 'assets/icons/verified-user.svg'
import plusCircle from 'assets/icons/plus-circle.svg'
import play from 'assets/icons/play.svg'
import pause from 'assets/icons/pause.svg'
import minusCircle from 'assets/icons/minus-circle.svg'
import filterWhite from 'assets/icons/filterWhite.svg'
import calendar from 'assets/icons/calendar.svg'
import dashboard from 'assets/icons/dashboard.svg'
import diagram from 'assets/icons/diagram.svg'
import newTable from 'assets/icons/new-table.svg'
import treeColumn from 'assets/icons/tree-column.svg'
import barChart from 'assets/icons/bar-chart.svg'
import inlineChart from 'assets/icons/inline-chart.svg'
import table from 'assets/icons/table.svg'
import diagram3 from 'assets/icons/diagram-3.svg'

export default {
  plusCircle,
  table,
  diagram3,
  inlineChart,
  diagram,
  treeColumn,
  barChart,
  newTable,
  calendar,
  dashboard,
  minusCircle,
  sortDown,
  sortUp,
  xCircle,
  accountLogout,
  filePdf,
  add,
  addWhite,
  fileVideo,
  arrowsAltV,
  fileWord,
  colorPalette,
  filter,
  edit,
  fullscreen,
  edit2,
  fullscreenExit,
  eye,
  play,
  // informationCircle,
  eyeOff,
  ionicIosAddCircle,
  face,
  ionicIosAddCircleOutline,
  featherEye,
  ionicIosarrowback,
  featherEyeOff,
  ionicIosRemoveCircle,
  featherFile,
  ionicIosRemoveCircleOutline,
  featherFileMinus,
  loader,
  featherFilePlus,
  mapFullscreen,
  featherFileText,
  materialEdit,
  featherMusic,
  movie,
  featherUserCheck,
  music,
  featherUsers,
  remove,
  fileAlt,
  tagFaces,
  fileAudio,
  trash,
  fileCode,
  trash2,
  fileContract,
  trashAlt,
  fileCsv,
  trashAlt1,
  fileDownload,
  trashAlt2,
  fileExcel,
  upload,
  uploadWhite,
  fileExport,
  user,
  fileImage,
  userAlt,
  fileImport,
  userCheck,
  fileInvoice,
  userCircle,
  fileInvoiceDollar,
  userEdit,
  fileMedicalAlt,
  users,
  fileMovie,
  verifiedUser,
  search,
  check,
  information,
  pause,
  download,
  clearFilterIcon,
  filterWhite
}
