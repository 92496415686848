import * as S from './styles'

type TModal = {
  handleClose: () => void
  open?: boolean
  noPadding?: boolean
  width?: string
  maxOverlay?: boolean
  children?: React.ReactNode
}

const Modal: React.FC<TModal> = ({
  open,
  noPadding,
  handleClose,
  width,
  maxOverlay,
  children
}) => {
  return (
    <S.Wrapper open={open} noPadding={noPadding}>
      <S.Overlay onClick={handleClose} maxOverlay={maxOverlay} />
      <S.Container width={width}> {children}</S.Container>
    </S.Wrapper>
  )
}

export default Modal
